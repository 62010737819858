import pkg from "../../package.json";

export const environment = {
  version: pkg.version,
  hmr: false,
  production: true,
  baseUrl: "https://crm-solutions-ebged0g5a5bdaqbb.eastus2-01.azurewebsites.net/api/v1/",
  signalRUrl: "https://crm-solutions-ebged0g5a5bdaqbb.eastus2-01.azurewebsites.net/hub/notification",
  googleMapskey: "AIzaSyCoF2frpi0FD7ZW0Y2NNiQ8NJ8ZqXiWoug",
  sentryDSN: "https://29b86756241592666f5796faab357349@o4505172191608832.ingest.us.sentry.io/4507232315441152",
  appTitle: (title: string) => `${title ? `${title} | ` : ""}Platino Solutions - Panel Administrativo`,
};
