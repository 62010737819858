import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { NavigationService } from "#root/core/navigation/navigation.service";
import { Navigation } from "#root/core/navigation/navigation.types";
import { Subject, takeUntil } from "rxjs";

import { AppConfig, Scheme } from "#root/core/config/app.config";
import { FormBuilder } from "@angular/forms";
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import { FuseConfigService } from "@fuse/services/config";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { environment } from "environments/environment";
import { cloneDeep } from "lodash";

@Component({
  selector: "dense-layout",
  templateUrl: "./dense.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  navigationAppearance: "default" | "dense" = "dense";
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  logo = "../../../../../assets/images/logo/dense-logo.png";
  logoIso = "../../../../../assets/images/logo/dense-logo.png";
  logoNormal = "../../../../../assets/images/logo/logo-p.png";
  isLogoIso = true;
  role = localStorage.getItem("rol");
  appVersion = environment.version;
  unmodifiedNavigation: Navigation;
  config: AppConfig;
  form = this.formBuilder.group({
    search: [""],
  });

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private configService: FuseConfigService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  @ViewChild("searchInput") searchInput: ElementRef;
  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
      this.navigation = cloneDeep(navigation);
      this.unmodifiedNavigation = cloneDeep(navigation);
    });

    this.listenForShortCut();
    this.configService.config$.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.config = config;
    });
    this.searchChanges();
    this.onClickItem();

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
      // Check if the screen is small
      this.isScreenSmall = !matchingAliases.includes("md");

      // Change the navigation appearance
      this.navigationAppearance = this.isScreenSmall ? "default" : "dense";
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  onInputMouseLeave(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.blur();
    }
  }

  listenForShortCut() {
    document.addEventListener("keydown", (e) => {
      if (e.ctrlKey && e.key === "k") {
        e.preventDefault();
        this.toggleNavigationAppearance();
        this.cdRef.markForCheck();
        setTimeout(() => {
          const searchBar = document.getElementById("search-bar-crm");

          if (searchBar) {
            searchBar.focus();
          }
        }, 100);
      }
    });
  }

  /**
   * Toggle the navigation appearance
   */
  toggleNavigationAppearance(): void {
    if (this.navigationAppearance === "dense") {
      this.logo = this.logo != this.logoIso ? this.logoIso : this.logoNormal;
      if (this.logo === this.logoIso) {
        this.isLogoIso = true;
      } else {
        this.isLogoIso = false;
      }
    } else {
      this.logo = this.logo != this.logoNormal ? this.logoNormal : this.logoIso;
      if (this.logo === this.logoNormal) {
        this.isLogoIso = false;
      } else {
        this.isLogoIso = true;
      }
    }
    this.navigationAppearance = this.navigationAppearance === "default" ? "dense" : "default";
  }

  searchChanges() {
    this.form.controls.search.valueChanges.subscribe((value) => {
      this.filterItems(value);
      this.cdRef.markForCheck();
    });
  }

  filterNavigationItem(value: string, item: FuseNavigationItem) {
    if (item?.children && item?.children?.length > 0) {
      const filteredChildren = item?.children
        .map((child) => this.filterNavigationItem(value, child))
        .filter((child) => child?.children?.length > 0 || child?.title?.toLowerCase().includes(value?.toLowerCase()));

      return {
        ...item,
        children: filteredChildren?.length > 0 ? filteredChildren : [],
      };
    } else {
      return item?.title?.toLowerCase()?.includes(value?.toLowerCase()) ? item : null;
    }
  }

  filterItems(value: string) {
    this.navigation.default = this.unmodifiedNavigation.default
      .map((item) => this.filterNavigationItem(value, item))
      .filter((item) => item?.children?.length > 0 || item?.title?.toLowerCase().includes(value?.toLowerCase()));

    this.cdRef.markForCheck();
  }

  onClickItem() {
    this._router.events.subscribe((event) => {});
  }

  downloadUserManual() {
    const link = document.createElement("a");
    link.href = "https://platinosoftware.blob.core.windows.net/distribuidora-platino/Manual_de_asesores_de_ventas_solutions.pdf";
    link.target = "_blank";
    link.download = "Manual_de_asesores_de_ventas.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  get isPurchaseAdvisor() {
    const roles = ["PurchasesAdvisor"];
    return roles.includes(this.role);
  }

  setScheme(scheme: Scheme): void {
    this.configService.config = { scheme };
  }
}
