import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { ApiResponseData } from "#interfacesv2/api/response/http-responses.interface";
import { HttpErrorResponse } from "@angular/common/http";
import { FuseAlertService } from "@fuse/components/alert";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  private _message: BehaviorSubject<{ message: string; errors: Array<string> | null } | null> = new BehaviorSubject(null);
  private _updateMessage: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _saveMessage: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private isNotSent: boolean = true;

  constructor(private alertService: FuseAlertService) {}

  get message$(): Observable<{ message: string; errors: Array<string> | null }> {
    return this._message.asObservable();
  }

  get updateMessage$(): Observable<string> {
    return this._updateMessage.asObservable();
  }

  get saveMessage$(): Observable<string> {
    return this._saveMessage.asObservable();
  }

  async updateAlert(message: string) {
    this._updateMessage.next(message);
    this.alertService.show("update-alert");
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.alertService.dismiss("update-alert"));
      }, 5000);
    });
  }

  async saveAlert(message: string) {
    this._saveMessage.next(message);
    this.alertService.show("save-alert");
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.alertService.dismiss("save-alert"));
      }, 5000);
    });
  }

  async errorAlert(errorArray: Array<string> | null, message: string) {
    if (this.isNotSent) {
      this._message.next({ message: message, errors: errorArray });
      this.isNotSent = false;
      this.alertService.show("error-alert");
      await new Promise((resolve) => {
        setTimeout(() => {
          this.isNotSent = true;
          resolve(this.alertService.dismiss("error-alert"));
        }, 5000);
      });
    } else {
      return;
    }
  }

  handleAlertResponse(response: HttpErrorResponse | ApiResponseData<any>) {
    if (response instanceof HttpErrorResponse) {
      this.errorAlert(
        response?.error?.errors ?? response?.error?.Errors,
        response?.error?.message ?? response?.error?.Message ?? response?.error?.title ?? response?.error?.title
      );
    } else {
      this.saveAlert(response.message);
    }
  }
}
