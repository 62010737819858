import { Roles } from "#interfacesv2/api/user/enum/roles.enum";

export enum RouteEnum {
  CRM = "crm",
  DASHBOARD_MAIN = "/dashboard/main",
  DASHBOARD_ADVISOR = "/dashboard/advisor",
  DASHBOARD_MERCH = "/dashboard/merch",
  CRM_CUSTOMERS = "/crm/customers",
  CRM_OPPORTUNITY = "/crm/opportunity",
  CRM_AD_CAMPAIGN = "/crm/adCampaign",
  CRM_ACTIVITIES = "/crm/activities",
  CRM_SALES_ADVISORS = "/crm/salesAdvisors",
  CRM_INVOICE = "/crm/invoice",
  CRM_DELIVERY_NOTE = "/crm/delivery-note",
  CRM_SALE_ORDER = "/crm/sale-order",
  CRM_BUSINESS_PARTNERS = "/crm/business-partners",
  PENDING = "pending",
  CRM_PENDING_DISCOUNT = "/crm/pending/discount",
  CRM_PROSPECTS = "/crm/prospects",
  REPORTS = "reports",
  CRM_REPORT_CUSTOMERS_ADVISOR = "/crm/report/customers-advisor",
  CRM_REPORT_QUOTED_PRODUCTS_CLIENT = "/crm/report/quoted-products-client",
  CRM_REPORT_ADVISOR_GOAL = "/crm/report/advisor-goal",
  CRM_REPORT_TOP_QUOTE = "/crm/report/top-quote",
  CRM_REPORT_CLIENTS_BY_QUOTED_PRODUCT = "/crm/report/clients-by-quoted-product",
  CRM_REPORT_TOP_SOLD = "/crm/report/top-sold",
  CRM_REPORT_ADVISOR_ACTIVITIES = "/crm/report/advisor-activities",
  CRM_REPORT_OPPORTUNITY_ORIGINS = "/crm/report/opportunity-origins",
  CRM_REPORT_MASTER = "/crm/report/master",
  CRM_REPORT_MASTER_QUOTATIONS = "/crm/report/master-quotations",
  CRM_REPORT_MASTER_LEADS = "/crm/report/master-leads",
  CRM_REPORT_MASTER_CLIENT = "/crm/report/master-client",
  CRM_REPORT_MASTER_PRODUCT = "/crm/report/master-product",
  CRM_REPORT_TOP_CUSTOMERS = "/crm/report/top-customers",
  CRM_REPORT_PARTICIPATION_BY_SUBCATEGORY = "/crm/report/participation-by-subcategory",
  CRM_REPORT_INVENTORY_ROTATION = "/crm/report/inventory-rotation",
  CRM_REPORT_INVENTORY_ANALYSIS_BY_WAREHOUSE = "/crm/report/inventory-analysis-by-warehouse",
  INVENTORY_PRODUCTS = "/inventory/products",
  INVENTORY_BRANDS = "/inventory/brands",
  INVENTORY_CATEGORY_PRODUCTS_CATEGORIES = "/inventory/categoryProducts/categories",
  INVENTORY_CATEGORY_PRODUCTS_SUBCATEGORIES = "/inventory/categoryProducts/subcategories",
  INVENTORY_CHARACTERISTICS = "/inventory/characteristics",
  INVENTORY = "inventory",
  ECOMMERCE_BUSINESS_DATA = "/ecommerce/businessData",
  ECOMMERCE_BRANCH = "/ecommerce/branch",
  ECOMMERCE_HERO_SLIDER = "/ecommerce/heroSlider",
  ECOMMERCE_OPINIONS = "/ecommerce/opinions",
  ECOMMERCE_BANNERS = "/ecommerce/banners",
  ECOMMERCE = "ecommerce",
  GLOBAL_CONFIG = "globalConfig",
  GLOBAL_CONFIG_SALES_CHANNEL = "/globalConfig/salesChannel",
  GLOBAL_CONFIG_PRICELISTS = "/globalConfig/pricelists",
  GLOBAL_CONFIG_WAREHOUSES = "/globalConfig/warehouses",
  GLOBAL_CONFIG_DOCUMENT_TYPE = "/globalConfig/documentType",
  GLOBAL_CONFIG_ORIGIN_TYPE_ORIGINS = "/globalConfig/origin/typeOrigins",
  GLOBAL_CONFIG_TYPE_ACTIVITY = "/globalConfig/typeActivity",
  GLOBAL_CONFIG_MEASURE_UNIT = "/globalConfig/measureUnit",
  GLOBAL_CONFIG_STATUS = "/globalConfig/status",
  GLOBAL_CONFIG_STATUS_LIST = "/globalConfig/status/list",
  GLOBAL_CONFIG_STATUS_TYPE_STATUS = "/globalConfig/status/typeStatus",
  GLOBAL_CONFIG_LOCATIONS = "/globalConfig/locations",
  GLOBAL_CONFIG_LOCATIONS_REGIONS = "/globalConfig/locations/regions",
  GLOBAL_CONFIG_LOCATIONS_DEPARTMENTS = "/globalConfig/locations/departments",
  GLOBAL_CONFIG_LOCATIONS_CITIES = "/globalConfig/locations/cities",
  GLOBAL_CONFIG_WIN_REASONS = "/globalConfig/winReasons",
  GLOBAL_CONFIG_LOSS_REASONS = "/globalConfig/lossReasons",
  GLOBAL_CONFIG_USERS = "/globalConfig/users",
}

export const ROUTEROLES = {
  [RouteEnum.CRM]: {
    roles: [
      Roles.SuperAdmin,
      Roles.Admin,
      Roles.Manager,
      Roles.SalesAdvisor,
      Roles.OfficeSalesAdvisor,
      Roles.CommunityManager,
      Roles.CreditManager,
      Roles.AfterSale,
      Roles.ReportAnalyst,
    ],
  },
  [RouteEnum.DASHBOARD_MAIN]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager, Roles.CreditManager, Roles.AfterSale, Roles.ReportAnalyst],
  },
  [RouteEnum.DASHBOARD_ADVISOR]: {
    roles: [Roles.SalesAdvisor, Roles.OfficeSalesAdvisor],
  },
  [RouteEnum.DASHBOARD_MERCH]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager, Roles.ReportAnalyst],
  },
  [RouteEnum.CRM_CUSTOMERS]: {
    roles: [
      Roles.SuperAdmin,
      Roles.Admin,
      Roles.Manager,
      Roles.SalesAdvisor,
      Roles.OfficeSalesAdvisor,
      Roles.CommunityManager,
      Roles.CreditManager,
      Roles.AfterSale,
    ],
  },
  [RouteEnum.CRM_OPPORTUNITY]: {
    roles: [
      Roles.SuperAdmin,
      Roles.Admin,
      Roles.Manager,
      Roles.SalesAdvisor,
      Roles.OfficeSalesAdvisor,
      Roles.CommunityManager,
      Roles.CreditManager,
      Roles.AfterSale,
    ],
  },
  [RouteEnum.CRM_AD_CAMPAIGN]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
  },
  [RouteEnum.CRM_ACTIVITIES]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager, Roles.SalesAdvisor, Roles.OfficeSalesAdvisor],
  },
  [RouteEnum.CRM_SALES_ADVISORS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.CRM_INVOICE]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
  },
  [RouteEnum.CRM_DELIVERY_NOTE]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
  },
  [RouteEnum.CRM_SALE_ORDER]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
  },
  [RouteEnum.CRM_BUSINESS_PARTNERS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
  },
  [RouteEnum.PENDING]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
  },
  [RouteEnum.CRM_PENDING_DISCOUNT]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
  },
  [RouteEnum.CRM_PROSPECTS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.SalesAdvisor, Roles.OfficeSalesAdvisor, Roles.CommunityManager],
  },
  [RouteEnum.REPORTS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_CUSTOMERS_ADVISOR]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_QUOTED_PRODUCTS_CLIENT]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_ADVISOR_GOAL]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_TOP_QUOTE]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_CLIENTS_BY_QUOTED_PRODUCT]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_TOP_SOLD]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_ADVISOR_ACTIVITIES]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_OPPORTUNITY_ORIGINS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_MASTER]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_MASTER_QUOTATIONS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_MASTER_LEADS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_MASTER_CLIENT]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_MASTER_PRODUCT]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_TOP_CUSTOMERS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_PARTICIPATION_BY_SUBCATEGORY]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_INVENTORY_ROTATION]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.CRM_REPORT_INVENTORY_ANALYSIS_BY_WAREHOUSE]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.ReportAnalyst, Roles.CommunityManager],
  },
  [RouteEnum.INVENTORY_PRODUCTS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager, Roles.SalesAdvisor, Roles.OfficeSalesAdvisor],
  },
  [RouteEnum.INVENTORY_BRANDS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.INVENTORY_CATEGORY_PRODUCTS_CATEGORIES]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.INVENTORY_CATEGORY_PRODUCTS_SUBCATEGORIES]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.INVENTORY_CHARACTERISTICS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.INVENTORY]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager, Roles.SalesAdvisor, Roles.OfficeSalesAdvisor],
  },
  [RouteEnum.ECOMMERCE_BUSINESS_DATA]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
  },
  [RouteEnum.ECOMMERCE_BRANCH]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.ECOMMERCE_HERO_SLIDER]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.ECOMMERCE_OPINIONS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.ECOMMERCE_BANNERS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.ECOMMERCE]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG_SALES_CHANNEL]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
  },
  [RouteEnum.GLOBAL_CONFIG_PRICELISTS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, ],
  },
  [RouteEnum.GLOBAL_CONFIG_WAREHOUSES]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, ],
  },
  [RouteEnum.GLOBAL_CONFIG_DOCUMENT_TYPE]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, ],
  },
  [RouteEnum.GLOBAL_CONFIG_ORIGIN_TYPE_ORIGINS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG_TYPE_ACTIVITY]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG_MEASURE_UNIT]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, ],
  },
  [RouteEnum.GLOBAL_CONFIG_STATUS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, ],
  },
  [RouteEnum.GLOBAL_CONFIG_STATUS_LIST]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, ],
  },
  [RouteEnum.GLOBAL_CONFIG_STATUS_TYPE_STATUS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, ],
  },
  [RouteEnum.GLOBAL_CONFIG_LOCATIONS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG_LOCATIONS_REGIONS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG_LOCATIONS_DEPARTMENTS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG_LOCATIONS_CITIES]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG_WIN_REASONS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG_LOSS_REASONS]: {
    roles: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.CommunityManager],
  },
  [RouteEnum.GLOBAL_CONFIG_USERS]: {
    roles: [Roles.SuperAdmin],
  },
};
